import React from "react";

function Home() {
  return (
    <div>
      <h1 class="Title">Hello, I'm Atosa.</h1>
      <h3 class="Description">I’m a second year Software Engineering student studying at
         Chalmers University of Technology and currently a summer intern at Volvo Group.
         Sometimes I create art too. Mostly though, I clown online about what the next 
        Taylor Swift re-recorded album might be.</h3>
    </div>
  )
  // return (
  //   <div class="aboutMe">
  //     <h1>Hello, I’m Atosa.</h1> <br/> <br/>
  //     <p class="aboutMeDescription"> I’m a second year Software Engineering student studying at Chalmers University of Technology and currently a summer intern at Volvo Group. Sometimes I create art too. Mostly though, I clown online about what the next Taylor Swift re-recorded album might be.</p>
  //   </div>
  // )
}

export default Home;